import Moderator from '@/models/bread/Moderator/eventBook'

export default {
    key: 'EventBook',
    name: {
        singular: 'Book',
        plural: 'Books',
    },
    parents: [
        'SchoolYear',
    ],
    grandParents:[
      'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}