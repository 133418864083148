import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text:i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: "Book",
          value: 'bookKey',

        }
      ],
    },
  },
  read: false,
  add: {
    fields: [{
        type: 'model',
        key: 'chapterKey',
        name: 'Book',
        model: 'book',
        optionText: 'name',
        defaultValue: 1,
      },
      
    ],
    rules: {
      bookKey: [v => !!v || 'Book is required'],
    },
  },
  edit: false,
  delete: true,
}